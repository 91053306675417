import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable"];
  static values = { toggleClass: String };

  toggle(event) {
    const toggleClass = this.toggleClassValue || "d-none";
    console.log(toggleClass)
    this.toggleableTargets.forEach(element => {
      element.classList.toggle(toggleClass);
    });
    event.currentTarget.classList.toggle("active");
  }
}